import { useState, useRef, useEffect } from "react";
import "./video.css";
const Video = ({ startLoad, loadPoster }) => {
  const videoRef = useRef(undefined);
  const [isPlaying, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  // const mobileSrc = (window.screen.width > 1400) ? "/video/відеопрезентація" : (window.screen.width > 1024 ? "/video/відеопрезентація-1400" : "/video/відеопрезентація-1024")
  const mobileSrc = "/video/відеопрезентація"
  let prefix = "";
  if (window.screen.width <= 1024) {
    prefix = "-1024"
  }

  useEffect(()=>{
    videoRef.current.muted = muted;
  },[muted])

  useEffect(()=>{
    isPlaying ? videoRef.current.play() : videoRef.current.pause()
  },[isPlaying])

  return (
    <div className={`video-section ${isPlaying ? "playing" : "stopped"}`}>
     <div className="video-wrapper">
      <video
        ref={videoRef}
        poster={loadPoster ? "/video/відеопрезентація.jpg" : ""}
        className="big"
        onClick={(e) => {
          e.target.blur();
          setPlaying((state)=>!state)
        }}
        onPause={()=>{
          if(isPlaying) setPlaying(false)
        }}
        onPlay={()=>{
          if(!isPlaying) setPlaying(true)
        }}
        onLoadedData={
          ()=>{
              setMuted(true)
          }

        }
        onEnded={(e)=>setPlaying((state)=>false)}
      >
        {startLoad ?
        <>
        <source
          src={mobileSrc + ".webm"}
          type="video/webm"
        />
        <source
          src={mobileSrc+ prefix +  ".mp4"}
          type="video/mp4"
        />
        </>
        : ""}
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div aria-label="Pełny ekran" title="Pełny ekran" tabIndex="0" className="cross"
      onClick={(e) => {
        e.target.blur();
        if (videoRef.current.requestFullscreen)
        videoRef.current.requestFullscreen();
        else if (videoRef.current.webkitRequestFullscreen)
        videoRef.current.webkitRequestFullscreen();
        else if (videoRef.current.msRequestFullScreen)
        videoRef.current.msRequestFullScreen();
      }}
      />
      <div aria-label="Prczycisk Wycisz / Podgłoś" title="Wycisz / Podgłoś" tabIndex="0" className={`cross mute ${!muted?"":"loud"}`}
      onClick={(e) => {
        e.target.blur();
        setMuted(state=>!state)
      }}
      />
      </div>
      <div className="video-caption">
        <h2>Перегляньте нашу відеопрезентацію.</h2>
        <div className="play"></div>
      </div>
    </div>
  );
};

export default Video;
