import { useState, useEffect, useRef } from "react";
import "./app.css";
import Header from "./header";
import Banner from "./banner";
import Tile from "./tile";
import Video from "./video";
import Form from "./form";
import Footer from "./footer";
import {onClickScroll} from "./utils"
function App() {
  const pdfTile = ()=> <Tile
              srcset="
              /Конфігурація/контейнер-компоненти_190.jpg 190w,
              /Конфігурація/контейнер-компоненти_431.jpg 431w,
              /Конфігурація/контейнер-компоненти_650.jpg 650w,
              /Конфігурація/контейнер-компоненти_817.jpg 817w,
              /Конфігурація/контейнер-компоненти_985.jpg 985w,
              /Конфігурація/контейнер-компоненти_1205.jpg 1205w,
              /Конфігурація/контейнер-компоненти_1220.jpg 1220w,
              /Конфігурація/контейнер-компоненти_1326.jpg 1326w,
              /Конфігурація/контейнер-компоненти_1318.jpg 1318w,
              /Конфігурація/контейнер-компоненти_1552.jpg 1552w,
              /Конфігурація/контейнер-компоненти_1759.jpg 1759w,
              /Конфігурація/контейнер-компоненти_1786.jpg 1786w,
              /Конфігурація/контейнер-компоненти_1840.jpg 1840w"
              src="/Конфігурація/контейнер-компоненти_1840.jpg"
              alt="Модульний контейнер - компоненти"
              startLoad={loader >= 2}
              title="КАТАЛОГ ПРОДУКТІВ"
              setOpen={setOpen}
              isOpen={isOpen}
              pdfPath="каталог.pdf"
            />
  const [isOpen, setOpen] = useState(undefined);
  const [isForceLoaded, setForceLoaded] = useState(false);
  const flag = useRef();
  const [fullWidthClass, setFullWidthClass] = useState("not-visable");
  const [scrollClass,setScrollClass] = useState("scroll-top")
  const [loader, setLoader] = useState();
  useEffect(() => {
    if (fullWidthClass === "fading") {
      setTimeout(function () {
        setFullWidthClass("not-visable");
      }, 300);
    }
  }, [fullWidthClass]);
  useEffect(() => {
    document.onkeydown = function (evt) {
      if (isOpen && isOpen.src) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
          isEscape = evt.key === "Escape" || evt.key === "Esc";
        } else {
          isEscape = evt.keyCode === 27;
        }
        if (isEscape) {
          setFullWidthClass("fading");
          setOpen((state) => undefined);
        }
      }
    };
  },[isOpen]);
  useEffect(() => {
    setTimeout(()=>{
      setForceLoaded((state)=>{
        if(state===false){
          window.dispatchEvent(new Event('load'));
          flag.current = true
          return true;
      } else return state
      })
    },2000)

    window.addEventListener('load', (event) => {
        setForceLoaded(true)
        setTimeout(()=>{
          let scroll = 0;
          let flag1 = true;
          let flag2 = true;
          let flag3 = true;
          setInterval(()=>{
            scroll = window.scrollY;
            if(scroll < window.outerHeight) {
              if(flag1===true){
                flag1=false;
                flag2=true;
                flag3=true;
                setScrollClass("top");
              }
            } else if(scroll < document.body.clientHeight - window.outerHeight - 300) {
              if(flag2===true){
                flag1=true;
                flag2=false;
                flag3=true;
                setScrollClass("center");
              }
            } else {
              if(flag3===true){
                flag1=true;
                flag2=true;
                flag3=false;
                setScrollClass("bottom");
              }
            }
          }, 1700);
          setLoader(1)
          setTimeout(()=>{
            setLoader(2)
            setTimeout(()=>{setLoader(3)},500)
          },500)
        },700)
    });
  },[])

  return (
    <div className={`app ${scrollClass}`}>
      <Header isSiteLoaded={true}/>
      <main>
      <Banner
        isSiteLoaded={true}
        id="Смарт-готелі-і-мотелі"
        srcset="
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_190.jpg 190w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_507.jpg 507w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_713.jpg 713w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_937.jpg 937w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1102.jpg 1102w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1250.jpg 1250w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1395.jpg 1395w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1502.jpg 1502w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1626.jpg 1626w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1823.jpg 1823w,
        /1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1860.jpg 1860w"
        src="/1-Смарт-готелі-і-мотелі/готель-екстерєр/готель-екстерєр_1860.jpg"
        alt="Смарт - Готель з модульних контейнерів, зовні"
        title={
          <span>
            <span>Смарт-готелі </span> і мотелі
          </span>
        }
      />

        <div className="tiles">
          <Tile
            title="Конфігурація"
            description=
            <ul>
              <li>міцна сталева конструкція, що дає змогу створювати будівлі до 3 поверхів</li>
              <li>можливість самостійного облаштування та зміни простору</li>
              <li>повноцінна ванна кімната, кухня та прихована електромережа забезпечують комфорт</li>
            </ul>
          />
          <Tile
            srcset="
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_280.jpg 280w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_549.jpg 549w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_767.jpg 767w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_921.jpg 921w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1026.jpg 1026w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1156.jpg 1156w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1339.jpg 1339w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1458.jpg 1458w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1586.jpg 1586w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1710.jpg 1710w,
            /1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1860.jpg 1860w"
            src="/1-Смарт-готелі-і-мотелі/кімната-в-готелі/кімната-в-готелі_1860.jpg"
            alt="інтер'єр готельного номера з контейнером"
            startLoad={true}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile
            srcset="
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_190.jpg 190w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_446.jpg 446w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_637.jpg 637w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_796.jpg 796w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_941.jpg 941w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1082.jpg 1082w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1191.jpg 1191w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1315.jpg 1315w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1428.jpg 1428w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1515.jpg 1515w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1566.jpg 1566w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1553.jpg 1553w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1759.jpg 1759w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1818.jpg 1818w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1819.jpg 1819w,
            /1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1820.jpg 1820w"
            src="/1-Смарт-готелі-і-мотелі/Смарт_-_Готель/Смарт_-_Готель_1820.jpg"
            alt="Смарт - Готель з модульних контейнерів MODULAR - вид ззовні"
            startLoad={true}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile

            srcset="
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_190.jpg 190w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_420.jpg 420w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_582.jpg 582w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_685.jpg 685w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_803.jpg 803w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_907.jpg 907w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1007.jpg 1007w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1093.jpg 1093w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1185.jpg 1185w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1271.jpg 1271w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1359.jpg 1359w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1434.jpg 1434w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1505.jpg 1505w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1520.jpg 1520w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1711.jpg 1711w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1727.jpg 1727w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1812.jpg 1812w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1848.jpg 1848w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1830.jpg 1830w,
            /1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1860.jpg 1860w"
            src="/1-Смарт-готелі-і-мотелі/діаграма-кімнати/діаграма-кімнати_1860.jpg"
            alt="Схема готельного номера в контейнері"
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile
            title="Якість"
            description=
            <ul>
              <li>Європейські стандарти та якість підтверджені сертифікатами</li>
              <li>понад 20 років досвіду та довіри, підтверджених співпрацею з арміями країн НАТО</li>
              <li>письмова гарантія, що забезпечує безпечне використання роками</li>
            </ul>
          />
          <Tile

            srcset="
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_190.jpg 190w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_430.jpg 430w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_599.jpg 599w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_757.jpg 757w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_903.jpg 903w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1045.jpg 1045w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1190.jpg 1190w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1316.jpg 1316w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1431.jpg 1431w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1425.jpg 1425w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1534.jpg 1534w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1793.jpg 1793w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1755.jpg 1755w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1788.jpg 1788w,
            /1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1860.jpg 1860w"
            src="/1-Смарт-готелі-і-мотелі/коридор-готель/коридор-готель_1860.jpg"

            alt="Інтер'єр готелю - вид на коридор"
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile
            srcset="
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_190.jpg 190w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_418.jpg 418w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_585.jpg 585w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_696.jpg 696w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_798.jpg 798w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_888.jpg 888w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_987.jpg 987w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1163.jpg 1163w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1249.jpg 1249w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1263.jpg 1263w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1327.jpg 1327w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1394.jpg 1394w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1651.jpg 1651w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1669.jpg 1669w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1739.jpg 1739w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1823.jpg 1823w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1854.jpg 1854w,
            /1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1860.jpg 1860w"
            src="/1-Смарт-готелі-і-мотелі/мотель-контейнери/мотель-контейнери_1860.jpg"
            alt="Мотель побудований з модульних контейнерів"
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile
            srcset="
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_190.jpg 190w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_387.jpg 387w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_531.jpg 531w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_652.jpg 652w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_759.jpg 759w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_859.jpg 859w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_954.jpg 954w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1043.jpg 1043w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1128.jpg 1128w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1200.jpg 1200w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1282.jpg 1282w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1356.jpg 1356w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1441.jpg 1441w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1508.jpg 1508w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1587.jpg 1587w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1658.jpg 1658w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1731.jpg 1731w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1808.jpg 1808w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1845.jpg 1845w,
            /1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1860.jpg 1860w"
            src="/1-Смарт-готелі-і-мотелі/номер-мотель/номер-мотель_1860.jpg"

            alt="Інтер’єр номера мотелю"
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />

          {pdfTile()}
        </div>
        <Banner
          isSiteLoaded={loader >= 2}
          id="офіси-магазини"
          srcset="
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_190.jpg 190w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_397.jpg 397w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_543.jpg 543w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_649.jpg 649w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_739.jpg 739w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_843.jpg 843w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_935.jpg 935w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1018.jpg 1018w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1092.jpg 1092w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1176.jpg 1176w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1250.jpg 1250w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1331.jpg 1331w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1396.jpg 1396w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1461.jpg 1461w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1589.jpg 1589w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1682.jpg 1682w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1672.jpg 1672w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1806.jpg 1806w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1780.jpg 1780w,
          /2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1860.jpg 1860w"
          src="/2-Офіси-та-магазини/магазин-претендент/магазин-претендент_1860.jpg"
          alt="Магазин, побудований з модульних контейнерів"
          title={
            <span>
              <span>Офіси</span>
              та магазини
            </span>
          }
        />
        <div className="tiles tiles2">
          <Tile
            title="Конфігурація"
            description=<ul>
                <li>можливість самостійного облаштування та зміни простору</li>
                <li>висока функціональність та мобільність, що дає змогу на використання у будь-якому місці</li>
                <li>різноманітні рішення з урахуванням Ваших очікувань</li>
            </ul>
          />
          <Tile
            srcset="
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_190.jpg 190w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_405.jpg 405w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_558.jpg 558w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_672.jpg 672w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_763.jpg 763w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_860.jpg 860w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_950.jpg 950w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1040.jpg 1040w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1117.jpg 1117w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1194.jpg 1194w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1271.jpg 1271w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1346.jpg 1346w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1458.jpg 1458w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1533.jpg 1533w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1535.jpg 1535w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1750.jpg 1750w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1824.jpg 1824w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1844.jpg 1844w,
            /2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1860.jpg 1860w"
            src="/2-Офіси-та-магазини/сайт-магазин-модульний/сайт-магазин-модульний_1860.jpg"
            alt="Елегантний магазин всередині контейнера"
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />

          <Tile
          srcset="
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_190.jpg 190w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_385.jpg 385w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_510.jpg 510w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_788.jpg 788w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_789.jpg 789w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_937.jpg 937w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1024.jpg 1024w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1107.jpg 1107w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1187.jpg 1187w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1238.jpg 1238w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1276.jpg 1276w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1360.jpg 1360w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1501.jpg 1501w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1582.jpg 1582w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1649.jpg 1649w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1725.jpg 1725w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1792.jpg 1792w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1834.jpg 1834w,
          /2-Офіси-та-магазини/схема-магазин/схема-магазин_1860.jpg 1860w"
          src="/2-Офіси-та-магазини/схема-магазин/схема-магазин_1860.jpg"
          alt="Схема магазинного контейнера"
          startLoad={loader >= 2}
          setOpen={setOpen}
          isOpen={isOpen}
          />
          <Tile
            srcset="
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_190.jpg 190w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_431.jpg 431w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_573.jpg 573w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_673.jpg 673w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_739.jpg 739w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_835.jpg 835w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_934.jpg 934w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1027.jpg 1027w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1118.jpg 1118w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1210.jpg 1210w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1289.jpg 1289w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1380.jpg 1380w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1449.jpg 1449w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1532.jpg 1532w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1601.jpg 1601w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1669.jpg 1669w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1744.jpg 1744w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1822.jpg 1822w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1842.jpg 1842w,
              /2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1860.jpg 1860w"
              src="/2-Офіси-та-магазини/інтерєр-контейнер/інтерєр-контейнер_1860.jpg"
            alt="Офіс будівельної компанії в контейнері"
            startLoad={true}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile
            title="Якість"
            description=
                  <ul>
                    <li>використання високоякісних матеріалів, які гарантують тривалий термін експлуатації</li>
                    <li>сертифікований виробничий процес, що забезпечує безпеку</li>
                  </ul>
          />

          <Tile
            srcset="
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_190.jpg 190w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_421.jpg 421w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_583.jpg 583w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_975.jpg 975w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_997.jpg 997w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1083.jpg 1083w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1180.jpg 1180w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1259.jpg 1259w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1345.jpg 1345w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1421.jpg 1421w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1492.jpg 1492w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1615.jpg 1615w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1776.jpg 1776w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1852.jpg 1852w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1824.jpg 1824w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1843.jpg 1843w,
            /2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1860.jpg 1860w"
            src="/2-Офіси-та-магазини/екстерєр-контейнер/екстерєр-контейнер_1860.jpg"
            alt="Комунальний контейнер для будівельної компанії"
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile
            srcset="
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_190.jpg 190w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_320.jpg 320w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_421.jpg 421w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_526.jpg 526w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_583.jpg 583w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_638.jpg 638w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_705.jpg 705w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_819.jpg 819w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_882.jpg 882w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_938.jpg 938w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1009.jpg 1009w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1019.jpg 1019w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1071.jpg 1071w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1121.jpg 1121w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1173.jpg 1173w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1224.jpg 1224w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1273.jpg 1273w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1324.jpg 1324w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1422.jpg 1422w,
          /2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1846.jpg 1846w"
          src="/2-Офіси-та-магазини/модульний-карєр/модульний-карєр_1846.jpg"
          alt="Елегантне кафе, побудоване з контейнерів, що стоїть на бруківці"
          startLoad={loader >= 2}
          setOpen={setOpen}
          isOpen={isOpen}
          />
          <Tile
          srcset="
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_190.jpg 190w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_416.jpg 416w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_572.jpg 572w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_676.jpg 676w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_856.jpg 856w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_957.jpg 957w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1065.jpg 1065w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1132.jpg 1132w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1141.jpg 1141w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1280.jpg 1280w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1359.jpg 1359w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1433.jpg 1433w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1514.jpg 1514w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1589.jpg 1589w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1662.jpg 1662w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1743.jpg 1743w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1823.jpg 1823w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1851.jpg 1851w,
          /2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1860.jpg 1860w"
          src="/2-Офіси-та-магазини/магазин-контейнери-Плоцьк/магазин-контейнери-Плоцьк_1860.jpg"
          alt="Магазин, побудований з контейнерів - вид ззовні"
          startLoad={loader >= 2}
          setOpen={setOpen}
          isOpen={isOpen}
          />

          {pdfTile()}
        </div>
        <Banner
          isSiteLoaded={loader >= 2}
          id="Будиночки-для-відпочинку"
          className="banner3"
          srcset="
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_190.jpg 190w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_406.jpg 406w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_565.jpg 565w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_681.jpg 681w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1059.jpg 1059w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1036.jpg 1036w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1127.jpg 1127w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1297.jpg 1297w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1279.jpg 1279w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1576.jpg 1576w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1610.jpg 1610w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1685.jpg 1685w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1752.jpg 1752w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1814.jpg 1814w,
          /3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1860.jpg 1860w"
          src="/3-Будиночки-для-відпочинку/будинок-екстерєр/будинок-екстерєр_1860.jpg"
          alt="Елегантний будинок для відпочинку, побудований з контейнерів, що стоїть на бруківці"
          title={
            <span>
              <span>Будиночки </span> для відпочинку
            </span>
          }
        />
        <div className="tiles tiles3">
          <Tile
            title="Конфігурація"
            description=<ul>
            <li>сучасні рішення гарантують безпечний відпочинок</li>
            <li>повноцінна ванна кімната, кухня та прихована електромережа забезпечують комфорт</li>
            <li>свобода облаштування та пристосування інтер’єру під власні потреби</li>
            </ul>

          />
          <Tile
            srcset="
            /3-Будиночки-для-відпочинку/раковина/раковина_190.jpg 190w,
            /3-Будиночки-для-відпочинку/раковина/раковина_414.jpg 414w,
            /3-Будиночки-для-відпочинку/раковина/раковина_569.jpg 569w,
            /3-Будиночки-для-відпочинку/раковина/раковина_715.jpg 715w,
            /3-Будиночки-для-відпочинку/раковина/раковина_806.jpg 806w,
            /3-Будиночки-для-відпочинку/раковина/раковина_879.jpg 879w,
            /3-Будиночки-для-відпочинку/раковина/раковина_983.jpg 983w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1063.jpg 1063w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1241.jpg 1241w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1273.jpg 1273w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1276.jpg 1276w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1679.jpg 1679w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1626.jpg 1626w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1696.jpg 1696w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1765.jpg 1765w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1819.jpg 1819w,
            /3-Будиночки-для-відпочинку/раковина/раковина_1860.jpg 1860w"
            src="/3-Будиночки-для-відпочинку/раковина/раковина_1860.jpg"
            alt="Елегантний умивальник у будинку для відпочинку - за ним дерев'яні декоративні елементи"
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />
          <Tile
            srcset="
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_190.jpg 190w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_400.jpg 400w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_556.jpg 556w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_686.jpg 686w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_786.jpg 786w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_899.jpg 899w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_992.jpg 992w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1175.jpg 1175w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1312.jpg 1312w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1316.jpg 1316w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1771.jpg 1771w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1601.jpg 1601w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1646.jpg 1646w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1718.jpg 1718w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1826.jpg 1826w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1845.jpg 1845w,
            /3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1860.jpg 1860w"
            src="/3-Будиночки-для-відпочинку/інтерєр-будинку-відпочинку/інтерєр-будинку-відпочинку_1860.jpg"
            alt="Інтер'єр будинку відпочинку. Білі стіни, 2 ліжка та стіл зі стільцями - стиль мінімалізм "
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />

          <Tile
            srcset="
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_190.jpg 190w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_453.jpg 453w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_643.jpg 643w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_804.jpg 804w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_957.jpg 957w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1077.jpg 1077w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1219.jpg 1219w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1343.jpg 1343w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1458.jpg 1458w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1579.jpg 1579w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1685.jpg 1685w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1795.jpg 1795w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1841.jpg 1841w,
            /3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1860.jpg 1860w"
            src="/3-Будиночки-для-відпочинку/mодульний-відкритий-будинок/mодульний-відкритий-будинок_1860.jpg"
            alt="Модульний будинок відпочинку стоячи на бруківці."
            startLoad={loader >= 2}
            setOpen={setOpen}
            isOpen={isOpen}
          />

          <Tile
            title="Якість"
            description=
            <ul>
              <li> утеплені стіни, дах та підлога забезпечать комфорт відпочинку в різних погодних умовах </li>
              <li> високоякісні екологічні матеріали та рішення роками запевняють відчуття безпеки</li>
            </ul>
          />

          <Tile
          srcset="
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_190.jpg 190w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_405.jpg 405w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_562.jpg 562w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_825.jpg 825w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_900.jpg 900w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_955.jpg 955w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1036.jpg 1036w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1138.jpg 1138w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1220.jpg 1220w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1294.jpg 1294w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1370.jpg 1370w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1441.jpg 1441w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1568.jpg 1568w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1573.jpg 1573w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1640.jpg 1640w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1711.jpg 1711w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1769.jpg 1769w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1830.jpg 1830w,
          /3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1846.jpg 1846w"
          src="/3-Будиночки-для-відпочинку/схема-будинок/схема-будинок_1846.jpg"
          alt="Схема модульного будинку для відпочинку. Дві кімнати з ліжками та меблями."
          startLoad={loader >= 2}
          setOpen={setOpen}
          isOpen={isOpen}
          />
          <Tile
          srcset="
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_190.jpg 190w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_373.jpg 373w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_561.jpg 561w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_722.jpg 722w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_840.jpg 840w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_971.jpg 971w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1003.jpg 1003w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1160.jpg 1160w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1225.jpg 1225w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1247.jpg 1247w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1434.jpg 1434w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1516.jpg 1516w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1582.jpg 1582w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1657.jpg 1657w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1725.jpg 1725w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1855.jpg 1855w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1856.jpg 1856w,
          /3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1860.jpg 1860w"
          src="/3-Будиночки-для-відпочинку/кольорова-ванна-кімната/кольорова-ванна-кімната_1860.jpg"
          alt="Барвиста ванна кімната в будинку відпочинку. Барвисті стіни, приємна акуратна раковина"
          startLoad={loader >= 2}
          setOpen={setOpen}
          isOpen={isOpen}
          />

          <Tile
          srcset="
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_190.jpg 190w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_457.jpg 457w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_648.jpg 648w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_809.jpg 809w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_958.jpg 958w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1089.jpg 1089w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1199.jpg 1199w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1334.jpg 1334w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1318.jpg 1318w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1453.jpg 1453w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1460.jpg 1460w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1655.jpg 1655w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1666.jpg 1666w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1740.jpg 1740w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1816.jpg 1816w,
          /3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1860.jpg 1860w"
          src="/3-Будиночки-для-відпочинку/екстерєр-котедж/екстерєр-котедж_1860.jpg"
          alt="Вхід до будинку для відпочинку контейнерів. Крізь скло видно елегантну кімнату."
          startLoad={loader >= 2}
          setOpen={setOpen}
          isOpen={isOpen}
          />

          {pdfTile()}
        </div>
        <div
          tabIndex={isOpen ? "0" : "-1"}
          onClick={(e) => {
            e.target.blur();
            if (isOpen) {
              setFullWidthClass("fading");
              setOpen((state) => undefined);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
              if (isOpen) {
                setFullWidthClass("fading");
                setOpen((state) => undefined);
              }
            }
          }}
          className={`full-size appear-full ${fullWidthClass}`}
        >
          <img
            className={`${fullWidthClass}`}
            sizes="(max-width: 1860px) 100vw, 1860px"
            srcSet={isOpen ? isOpen.srcSet : undefined}
            src={isOpen ? isOpen.src : undefined}
            alt=""
            onLoad={() => {
              setFullWidthClass("visable");
            }}
            onError={() => {
              setFullWidthClass("visable");
            }}
          />
          <div className="cross revert"></div>
        </div>
        <div
          className={`loading-container ${isOpen ? "src-setted" : "not-src"}`}
        >
          <div className="loading-shape shape1"></div>
          <div className="loading-shape shape2"></div>
          <div className="loading-shape shape3"></div>
        </div>
        <Video startLoad={loader >= 3} loadPoster={loader >= 2}  />
        <button title="Перейдіть до контактної форми" aria-label="Перейдіть до контактної форми" onClick={(e)=>onClickScroll(e,"#contact",false,true)} className="go-to-contact go-to-button"/>
        <button title="Повернутися до меню" aria-label="Повернутися до меню" onClick={(e)=> {
          e.target.blur()
          window.scrollTo({
  top: 0
})}} className="go-to-top go-to-button"/>
        <Form loadImg={loader >= 2} startLoad={loader >= 2} />
      </main>
      <Footer />
    </div>
  );
}

export default App;
