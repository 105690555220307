import React from "react";
import {onClickScroll} from "../utils"
const Menu = ({imgClass}) => {

  return <nav>
    <ul className={`menu ${imgClass}`}>
      <li><a onClick={(e)=>onClickScroll(e,"#Смарт-готелі-і-мотелі",true)
      } href="#Смарт-готелі-і-мотелі"><span>Смарт-готелі </span> і мотелі</a></li>
      <li><a onClick={(e)=>onClickScroll(e,"#офіси-магазини",true)} href="#офіси-магазини"><span>Офіси </span> та магазини</a></li>
      <li><a onClick={(e)=>onClickScroll(e,"#Будиночки-для-відпочинку",true)} href="#Будиночки-для-відпочинку"><span>Будиночки </span> для відпочинку</a></li>
    </ul>
  </nav>
};

export default Menu;
