import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <div className="cookie">
        Ми використовуємо файли cookie на веб-сайті. Ви можете вимкнути їх у
        налаштуваннях браузера.
      </div>
      <footer>
        <div className="footer">
          <div className="left-footer footer-side">
            <div className="uk-flag">
              <div className="bar up" />
              <div className="bar down" />
            </div>
            <div className="footer-note">
              <b>Сергій Красуленко</b>
              <b>Директор зі східних ринків</b>
              <span>тел .: +48 880 308 225 (WhatsApp)</span>
              <span>
                електрона пошта:{" "}
                <a href="mailto:sergii.krasulenko@modularsystem.pl">
                  sergii.krasulenko@modularsystem.pl
                </a>
              </span>
              <a
                href="https://modularsystem.pl/en/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="модульної системи - домашня сторінка"
              >
                www.modularsystem.pl
              </a>
            </div>
          </div>
          <div className="right-footer footer-side">
            <a
              className="logo-modular-wrapper"
              href="https://modularsystem.pl/en/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="модульної системи - домашня сторінка"
            >
              <div
                className="logo-modular"
                style={{
                  background:
                    'url("/header/modular-logo-red.png") no-repeat right / contain',
                }}
              />
            </a>
          </div>
        </div>
        <a
          href="https://modularsystem.pl/dotacje-i-granty/dotacje-ue-2/"
          className="pm-logos"
          aria-label="Більше інформації про програму польських технологічних мостів"
        >
          <img src="/img/4-fundusze.png" alt="" />
          <img src="/img/2-pol.png" alt="" />
          <img src="/img/3-agencja.png" alt="" />
          <img src="/img/1-ue.jpeg" alt="" />
        </a>
      </footer>
    </>
  );
};

export default Footer;
