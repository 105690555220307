import { useState, useEffect, useRef } from "react";
import "./header.css";
import Menu from "./Menu.jsx";
import {dataSrc} from "../utils"

import {onClickScroll} from "../utils"
const Header = ({isSiteLoaded}) => {
  const [imgClass, setImgClass] = useState("hidden");
  const image = useRef();
  useEffect(()=>{
    let timeout = setTimeout(()=>{
      if(image && image.current.complete) {
        setImgClass("visable")
      }
    },30)
    return ()=>clearTimeout(timeout)
  },[])
  return (
    <header>
      <div className="wrapper-menu wrapper-vertical">
        <a
          className="logo"
          href="https://modularsystem.pl/en/"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="модульної системи - домашня сторінка"
        >
          <img
            height="84"
            width="242"
            src={"/header/modular-логотип компанії.png"}
            alt="Modular - логотип компанії"
          />
        </a>
      </div>
      <Menu />
      <div className={`caption`}>
        <h1>
          <span>модульні</span> системи
        </h1>
        <div className="caption-inner">
          <ul>
          <li>Завдяки нашим рішенням Ви здобуваєте:</li>
            <li> мобільні приміщення, що швидко будуються </li>
            <li> високоякісні екологічні матеріали, що гарантують безпеку </li>
            <li> комплексні рішення з урахуванням різних потреб </li>
          </ul>
        </div>
      </div>
      <picture>
        <source media="(orientation:portrait)"
        sizes="(max-width: 1024px) 100vw, 1024px"
        srcSet={isSiteLoaded ? `
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_300.jpg 300w,
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_456.jpg 456w,
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_480.jpg 480w,
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_769.jpg 769w,
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_810.jpg 810w,
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_919.jpg 919w,
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_1014.jpg 1014w,
        /header/Modular-Штаб-квартира/Modular-Штаб-квартира-portrait_1024.jpg 1024w` : dataSrc + " 300w"}
        />
        <img
          ref={image}
          className={"background appearing " + imgClass}
          onLoad={() => {
            if(image.current.src!==dataSrc) setImgClass("visable")
          }}
          onError={() => {
            if(image.current.src!==dataSrc) setImgClass("visable")
          }}
          sizes="(max-width: 1800px) 100vw, 1800px"
          srcSet={isSiteLoaded ? `
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_190.jpg 190w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_522.jpg 522w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_751.jpg 751w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_937.jpg 937w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1113.jpg 1113w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1272.jpg 1272w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1428.jpg 1428w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1543.jpg 1543w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1667.jpg 1667w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1757.jpg 1757w,
          /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1800.jpg 1800w` : dataSrc + " 300w"}
          src={isSiteLoaded ? "/header/Modular-Штаб-квартира/Modular-Штаб-квартира_1800.jpg" : dataSrc}
          alt="Штаб-квартира модульної системи - виробник контейнерів"
        />
      </picture>

      <div className="wrapper-button wrapper-vertical">
        {" "}
        <button onClick={
              (e)=>onClickScroll(e,"#Смарт-готелі-і-мотелі",true)
        }>
          можливості<span className="arrow-container"></span>
        </button>
      </div>
    </header>
  );
};

export default Header;
