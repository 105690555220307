import { useState, createRef, useEffect, useRef } from "react";
import "./form.css";
import ReCAPTCHA from "react-google-recaptcha";


const Form = ({ startLoad,loadImg }) => {
  const [imageClass, setImageClass] = useState("not-visable");
  const recaptchaRef = createRef();
  const [submitedClass, setSubmited] = useState(false);
  const image = useRef();
  useEffect(() => {
       if (image.current && image.current.complete){
         setTimeout(()=>setImageClass("visable"),10);
       }
   }, [setImageClass])
  return (
    <div id={"contact"} className="form-container">
      <div className="form-side left-side">
        {loadImg ? (
          <img
            ref={image}
            className={`appearing ${imageClass}`}
            onLoad={() => {
              setImageClass("visable");
            }}
            sizes="(max-width: 1024px) 100vw, 50vw"
            onError={() => {
              setImageClass("visable");
            }}
            srcSet="
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_190.jpg 190w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_522.jpg 522w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_751.jpg 751w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_937.jpg 937w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1113.jpg 1113w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1272.jpg 1272w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1428.jpg 1428w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1543.jpg 1543w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1667.jpg 1667w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1757.jpg 1757w,
            /header/Modular-Штаб-квартира/Modular-Штаб-квартира_1800.jpg 1800w"
            src="/header/Modular-Штаб-квартира/Modular-Штаб-квартира_1800.jpg"
            alt="Штаб-квартира модульної системи - виробник контейнерів"
          />
        ) : (
          ""
        )}
        <div className="caption">
          <h2>зв'яжіться з нами</h2>
          <p>
          Залиште нам свої контактні дані. Напишіть те, що вас цікавить і ми зв'яжемося з Вами
          </p>
        </div>
      </div>
      <div className="form-side right-side">
        <div className={`form-sended ${submitedClass}`}>
        <div className="sended-title is-ok"> Надіслано правильно</div>
        <div className="sended-title is-not-ok"> Вибачте, ваше повідомлення не вдалося надіслати. Спробуйте ще раз</div>
        <div className="ok sign">✓</div>
        <div className="error sign">✗</div>
        <button onClick={()=>{setSubmited(false)}} className="refresh">Надішліть нову форму</button>
        </div>
        <form id="contact-form" onSubmit={(e) => {
            e.preventDefault();
            const recaptchaValue = recaptchaRef.current.getValue();
            if(recaptchaValue){
            setSubmited("waiting");
            const data = new FormData(e.target);
              fetch('/mail.php',{
                method: 'POST',
                body: data
              }).then(r => r.text()).then(r => {
                if (r === 'ok') {
                    setSubmited("send");
                } else {
                  setSubmited("send error");
                        }

              })
              recaptchaRef.current.reset();
            }
              else setSubmited("no-captcha")
           }
        } aria-label="Контактний формуляр">
        <h3>Ми підлаштуємо елементи під ваші потреби</h3>
          <div className="form-group">
            <input
              id="name"
              name="name"
              required
              type="text"
              className="form-text"
              onChange={(e) => {
                e.target.dataset.value=e.target.value
              }}
            />
            <label htmlFor="name">ім’я та прізвище</label>
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              className="form-text"
              required
              onChange={(e) => {
                e.target.dataset.value=e.target.value
              }}
            />
            <label htmlFor="email">електронна пошта</label>
          </div>
          <div className="form-group">
            <textarea
              id="msg"
              name="msg"
              required
              className="form-text"
              onChange={(e) => {
                e.target.dataset.value=e.target.value
              }}
            />
            <label htmlFor="msg">Зміст повідомлення</label>
          </div>
          <button type="submit" className={`btn btn-primary btn-submit ${submitedClass==="waiting" ? "waiting" :""}`}>
            подати
          </button>
          <fieldset className={submitedClass==="no-captcha" ? "no-captcha" : ""}>
            <div className="form-group-checkbox">
            <div className="wrapper">
              <input
                type="checkbox"
                id="agree-adds"
                name="agree-adds"
                value="Я погоджуюсь отримувати маркетингову інформацію та комерційні пропозиції від MODULAR"
              />
              </div>
              <label htmlFor="agree-adds">Я погоджуюсь отримувати маркетингову інформацію та комерційні пропозиції від Modular System Sp. z o.o
              </label>
            </div>
            <div className="form-group-checkbox">
            <div className="wrapper">
              <input
                required
                type="checkbox"
                id="agree-data"
                name="consent_data"
                value="Я приймаю Політику конфіденційності"
              />
              </div>
              <label htmlFor="agree-data">*Я приймаю <a target="_blank" rel="noreferrer noopener" href={process.env.REACT_APP_URL + "policy.pdf"}>Політику конфіденційності</a>.
 </label>
            </div>
            {startLoad ? <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA}
              hl="uk"
              onChange={()=>setSubmited("captcha")}
            /> : ""}
          </fieldset>
        </form>
      </div>
    </div>
  );
};
export default Form;
