import {useState, useEffect, useRef} from 'react';
import "./banner.css";
import {dataSrc} from "../utils"

const Banner = ({title, alt, src, srcset,id, isSiteLoaded, style}) => {
  const [imageClass,setImageClass] = useState("not-visable")
  const image = useRef();

  useEffect(() => {
       if (image.current && image.current.complete && image.current.src!==dataSrc){
           setTimeout(()=>setImageClass("visable"),50);
       }
   }, [setImageClass])
  return <div className="banner">
    <img
      style={style}
      ref={image}
      className={`appearing ${imageClass}`}
      onLoad={()=>{
          if(image.current.src!==dataSrc) setImageClass("visable")
      }}
      onError={()=>{
        setTimeout(()=>setImageClass("visable"),10);
      }}
      sizes="(max-width: 1800px) 100vw, 1800px"
      srcSet={isSiteLoaded ? srcset : (dataSrc+" 100w")}
      src={isSiteLoaded ? src: dataSrc} alt={alt}/>
    <h2 id={id}>{title}</h2>
  </div>
};

export default Banner;
