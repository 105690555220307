import { useState, useEffect, useRef } from "react";
import "./tile.css";
import {dataSrc} from "../utils"

const Tile = ({
  title,
  sizes,
  startLoad,
  alt,
  src,
  srcset,
  description,
  setOpen,
  isOpen,
  pdfPath,
  style
}) => {
  const [imageClass, setImageClass] = useState("not-visable");
  const [tileActive,setTileActive] = useState(false);
  useEffect(()=>{
    if(!isOpen){
      setTileActive(false);
    }
  },[isOpen])

  const image = useRef();

  useEffect(() => {
       if (image.current && image.current.complete && image.current.src!==dataSrc){
          setTimeout(()=>setImageClass("visable"),10);
       }
   },[setImageClass])

  const TileWrapper = (
    <>
      {src ? (
        <img
          ref={image}
          style={style}
          tabIndex="0"
          className={`appearing ${imageClass}`}
          onLoad={() => {
            if(image.current.src!==dataSrc){
              setImageClass("visable");
            }
          }}
          onError={() => {
            if(image.current.src!==dataSrc){
              setImageClass("visable");
            }
          }}
          sizes={startLoad ? (sizes ? sizes :"(max-width: 1024px) 100vw, 30vw") : "(max-width: 1024px) 100vw, 30vw"}
          srcSet={startLoad ? srcset : (dataSrc+" 300w")}
          src={startLoad ? src : dataSrc}
          alt={alt}
          onClick={(e) => {
            e.target.blur();
            if(!pdfPath) {
              setOpen({src:src,srcSet:srcset})
              setTileActive((state)=>!state)
            }
        }
        }
        onKeyPress={(e) => {
          if(e.key === 'Enter'){
            e.target.blur();
            if(!pdfPath) {
              setOpen({src:src,srcSet:srcset})
              setTileActive((state)=>!state)
            }
          }

        }
      }
        />
      ) : (
        ""
      )}

      <div className="cross"></div>
      <div className={`viewer ${isOpen && isOpen.src && tileActive ? "open" : "close"}`}></div>
      <div className="caption">
        <h3>{title}</h3>
        {description}
      </div>
    </>
  );

  return pdfPath ? (
    <a
      className="tile"
      target="_blank"
      rel="noreferrer noopener"
      href={process.env.REACT_APP_URL + pdfPath}
    >
      {TileWrapper}
    </a>
  ) : (
    <div className={`tile ${src ? "" : "no-image"}`}>{TileWrapper}</div>
  );
};

export default Tile;
